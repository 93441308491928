import React from 'react'
import { Calendar } from 'primereact/calendar'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrint, faCheckCircle, faSpinner } from '@fortawesome/free-solid-svg-icons'
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Link } from 'react-router-dom'
import { Toast as Growl } from 'primereact/toast'
import '../../scss/viewttn.scss'
import { SERVER_API, API_URL } from '../../common/env'


export default class ViewTtn extends React.Component {
    constructor(props) {
        super(props)
        let d1 = new Date()
        d1.setDate(1)
        let d2 = new Date()
        this.state = {
            dates: [d1, d2],
            itemSelected: null,
            items: [],
            isLoaded: false,
        }
    }

    load = () => {
        this.setState({ isLoaded: true })
        fetch(`${SERVER_API}/ttn/?date1=${this.state.dates[0]}&date2=${this.state.dates[1]}`, {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('jwt')
            }
        })
            .then(data => { return data.json() })
            .then(json => {
                this.setState({ items: json.data, isLoaded: false })
            })
            .catch(err => {
                console.log(err)
                this.setState({ isLoaded: false })
            })
    }

    onTtnLoadClick = e => {
        if (!this.state.dates || !this.state.dates[1]) {
            this.growl.show({ severity: 'error', summary: 'Помилка!', detail: "Не вказано період!" })
            return
        }
        /** */
        this.load()
    }

    componentDidMount() {
        document.title = `EXMOTO: Просмотр накладных [${localStorage.getItem('login')}]`
        this.load()
    }


    render() {

        let accord
        if (!this.state.isLoaded) {
            accord = this.state.items.map(item => {
                let d = item.dt_created
                d = d.substr(0, 10) + ' / ' + d.substr(11, 5)
                return (
                    <AccordionTab header={"№: " + item.wb_no + " (Дата/час формування: " + d + ")"} key={item.id}>
                        {/* <Link to={`/ttn/view/${item.id}`} target="_blank"><FontAwesomeIcon icon={faPrint} />&nbsp;Друк</Link> */}
                        {/* <Link to={`${SERVER_API}ttn`} target="_blank"><FontAwesomeIcon icon={faPrint} />&nbsp;Друк</Link> */}
                        <a href={`${API_URL}/ttn/print?id=${item.id}&jwt=${localStorage.getItem('jwt')}`} target="_blank"><FontAwesomeIcon icon={faPrint} />&nbsp;Друк</a> 
                        <div className="box-table">
                            <div>
                                <table>
                                    <thead>
                                        <tr><th colSpan={2}>Відправник</th></tr>
                                    </thead>
                                    <tbody>
                                        <tr><td>Страна</td><td>{item.sender_country_name}</td></tr>
                                        <tr><td>Нас.пункт</td><td>{item.org}&nbsp;&nbsp;{item.sender_city_type_name}.{item.sender_city_name}</td></tr>
                                        <tr><td>Область</td><td>{item.sender_state_name} {item.sender_state_type_name}</td></tr>
                                        <tr><td>Район</td><td>{item.sender_distr_name || ''}</td></tr>
                                        <tr><td>Улица</td><td>{item.sender_street_name || '' + item.sender_street_type || ''}</td></tr>
                                        <tr><td>Дом</td><td>{item.sender_bldg || ''}</td></tr>
                                        <tr><td>Квартира</td><td>{item.sender_flat || ''}</td></tr>
                                        <tr><td>Компания</td><td>{item.sender_client_name}</td></tr>
                                        <tr><td>Контактое лицо</td><td>{item.sender_contact_name}</td></tr>
                                        <tr><td>Телефон</td><td>{item.sender_phone}</td></tr>
                                    </tbody>
                                </table>
                            </div>
                            <div>
                                <table>
                                    <thead>
                                        <tr><th colSpan={2}>Отримувач</th></tr>
                                    </thead>
                                    <tbody>
                                        <tr><td>Страна</td><td>{item.receiver_country_name}</td></tr>
                                        <tr><td>Нас.пункт</td><td>{item.org}&nbsp;&nbsp;{item.receiver_city_type_name}.{item.receiver_city_name}</td></tr>
                                        <tr><td>Область</td><td>{item.receiver_state_name} {item.receiver_state_type_name}</td></tr>
                                        <tr><td>Район</td><td>{item.receiver_distr_name ? item.receiver_distr_name : ''}</td></tr>
                                        <tr><td>Улица</td><td>{item.receiver_street_name || '' + item.receiver_street_type || ''}</td></tr>
                                        <tr><td>Дом</td><td>{item.receiver_bldg || ''}</td></tr>
                                        <tr><td>Квартира</td><td>{item.receiver_flat || ''}</td></tr>
                                        <tr><td>Компания</td><td>{item.receiver_client_name}</td></tr>
                                        <tr><td>Контактое лицо</td><td>{item.receiver_contact_name}</td></tr>
                                        <tr><td>Телефон</td><td>{item.receiver_phone}</td></tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <hr />

                        <div className="box-table">
                            <div>
                                <table>
                                    <thead>
                                        <tr><th colSpan={2}>Параметри</th></tr>
                                    </thead>
                                    <tbody>
                                        <tr><td>Плательщик</td><td>{item.payer_name}</td></tr>
                                        <tr><td>Количество мест</td><td>{item.place_count}</td></tr>
                                        <tr><td>Оплата</td><td>{item.payment_name}</td></tr>
                                    </tbody>
                                </table>
                            </div>
                            <div>
                                <table>
                                    <thead>
                                        <tr><th colSpan={2} style={{ color: "white" }}>Параметри</th></tr>
                                    </thead>
                                    <tbody>
                                        <tr><td>Формат</td><td>{item.cargo_name}</td></tr>
                                        <tr><td>Доставка</td><td>{item.delivery_name}</td></tr>
                                        <tr><td>Оценочная стоимость, грн.</td><td>{item.cost.toFixed(2)}</td></tr>
                                    </tbody>
                                </table>
                            </div>
                            <div>
                                <table>
                                    <thead>
                                        <tr><th colSpan={2} style={{ color: "white" }}>Параметри</th></tr>
                                    </thead>
                                    <tbody>
                                        <tr><td>Сервис</td><td>{item.service_name}</td></tr>
                                        <tr><td>Тип вложения</td><td>{item.attachment_name}</td></tr>
                                        <tr><td>Вес, кг</td><td>{item.weight}</td></tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <hr />

                        <div className="box-table">
                            <div className="chg">
                                <table>
                                    <thead>
                                        <tr><th colSpan={2}>Розрахункова вартість доставки</th></tr>
                                    </thead>
                                    <tbody>
                                        <tr><td>Основной (базовый) тариф без НДС, грн.</td><td>{item.b_chg.toFixed(2)}</td></tr>
                                        <tr><td>Оценочный тариф (1% от заявленной стоимости), грн.</td><td>{item.v_chg.toFixed(2)}</td></tr>
                                        <tr><td>Дополнительный тариф за дооформление, грн.</td><td>{item.a_chg.toFixed(2)}</td></tr>
                                        <tr><td>Сумма тарифов без НДС, грн.</td><td>{(item.b_chg + item.v_chg + item.a_chg).toFixed(2)}</td></tr>
                                        <tr><td>НДС, грн.</td><td>{((item.b_chg + item.v_chg + item.a_chg) * 0.2).toFixed(2)}</td></tr>
                                        <tr><td>Итого с НДС, грн.</td><td>{item.t_chg.toFixed(2)}</td></tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <hr />

                        <div className="box-textarea" data-note={'Oпис'}>
                            <p>{item.note}</p>
                        </div>

                        <hr />
                    </AccordionTab>
                )
            })
        }

        return (
            <div className="cabinet-section">

                <div className="container">
                    <Growl ref={(el) => this.growl = el}></Growl>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <Link to="/"><img src="/img/logo_1.png" alt="logo" className="logo" /></Link>
                        &nbsp;&nbsp;
                        <Link to="/order">Создать новый заказ</Link>
                    </div>

                    <h1 className="cabinet-section-title">Накладные</h1>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <span>Період</span>
                        <Calendar style={{ margin: "0 5px" }}
                            selectionMode="range"
                            dateFormat="dd.mm.yy"
                            value={this.state.dates}
                            onChange={(e) => this.setState({ dates: e.value })} />
                        <a href='#0' onClick={this.onTtnLoadClick}><FontAwesomeIcon icon={faCheckCircle} /></a>
                    </div>

                    <div style={{ marginTop: "20px" }}>
                        {this.state.isLoaded ? <span><FontAwesomeIcon icon={faSpinner} spin />Завантаження ...</span> :
                            this.state.items.length ? <Accordion>{accord}</Accordion> :
                                <span style={{ color: "gray", fontSize: "2em" }}>Відсутні дані для перегляду</span>}
                    </div>
                </div>
            </div>
        )
    }

}
