import React from 'react'
import { useState } from 'react'
import { Calendar } from 'primereact/calendar'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrint, faCheckCircle, faSpinner, faMoneyBill, faEdit, faTrash, faCopy } from '@fortawesome/free-solid-svg-icons'
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Link } from 'react-router-dom'
import { Toast as Growl } from 'primereact/toast'
import '../../scss/viewttn.scss'
import { SERVER_API, API_URL } from '../../common/env'
import '../../scss/order.scss'
import { PayDialog } from './paydialog'


const TabItem = props => {
  const { item } = props
  return (
    <>
      <div className="box-table">
        <div>
          <table>
            <thead>
              <tr><th colSpan={2}>Відправник</th></tr>
            </thead>
            <tbody>
              <tr><td>Країна</td><td>{item.sender_country_name}</td></tr>
              <tr><td>Нас.пункт</td><td>{item.sender_city_type_name}.{item.sender_city_name}</td></tr>
              <tr><td>Область</td><td>{item.sender_state_name} {item.sender_state_type_name}</td></tr>
              <tr><td>Район</td><td>{item.sender_distr_name || ''}</td></tr>
              <tr><td>Вулиця</td><td>{item.sender_street_name || '' + item.sender_street_type || ''}</td></tr>
              <tr><td>Будинок</td><td>{item.sender_bldg || ''}</td></tr>
              <tr><td>Квартира</td><td>{item.sender_flat || ''}</td></tr>
              <tr><td>Компанія</td><td>{item.sender_client_name || ''}</td></tr>
              <tr><td>Контакта особа</td><td>{item.sender_contact_name || ''}</td></tr>
              <tr><td>Телефон</td><td>{item.sender_phone || ''}</td></tr>
            </tbody>
          </table>
        </div>
        <div>
          <table>
            <thead>
              <tr><th colSpan={2}>Отримувач</th></tr>
            </thead>
            <tbody>
              <tr><td>Країна</td><td>{item.receiver_country_name}</td></tr>
              <tr><td>Нас.пункт</td><td>{item.receiver_city_type_name}.{item.receiver_city_name}</td></tr>
              <tr><td>Область</td><td>{item.receiver_state_name} {item.receiver_state_type_name}</td></tr>
              <tr><td>Район</td><td>{item.receiver_distr_name ? item.receiver_distr_name : ''}</td></tr>
              <tr><td>Вулиця</td><td>{item.receiver_street_name || '' + item.receiver_street_type || ''}</td></tr>
              <tr><td>Будинок</td><td>{item.receiver_bldg || ''}</td></tr>
              <tr><td>Квартира</td><td>{item.receiver_flat || ''}</td></tr>
              <tr><td>Компанія</td><td>{item.receiver_client_name}</td></tr>
              <tr><td>Контакта особа</td><td>{item.receiver_contact_name}</td></tr>
              <tr><td>Телефон</td><td>{item.receiver_phone}</td></tr>
            </tbody>
          </table>
        </div>
      </div>

      <hr />

      <div className="box-table">
        <div>
          <table>
            <thead>
              <tr><th colSpan={2}>Параметри</th></tr>
            </thead>
            <tbody>
              <tr><td style={{ width: "30%" }}>Сервіс</td><td>{item.service_name}</td></tr>
              <tr><td>Формат</td><td>{item.cargo_name}</td></tr>
              <tr><td>Кількість місць</td><td>{item.place_count}</td></tr>
              <tr><td>Вага, кг</td><td>{item.weight}</td></tr>
              <tr><td>Доставка</td><td>{item.delivery_name}</td></tr>
              <tr><td>Тип кореспонденції</td><td>{item.attachment_name}</td></tr>
              <tr><td>Оціночна вартість, грн.</td><td>{item.cost}</td></tr>
              <tr><td>Платник</td><td>{item.payer_name}</td></tr>
              <tr><td>Спосіб оплати</td><td>{item.payment_name}</td></tr>
            </tbody>
          </table>
        </div>
      </div>

      <hr />

      <div className="box-textarea" data-note={'Описание'}>
        <p>{item.note}</p>
      </div>

      <hr />

      <div className="box-table">
        <div className="chg">
          <table>
            <thead>
              <tr><th colSpan={2}>Розрахункова вартість доставки</th></tr>
            </thead>
            <tbody>
              <tr><td>Основний (базовий) тариф без ПДВ, грн.</td><td>{item.b_chg.toFixed(2)}</td></tr>
              <tr><td>Оціночний тариф (1% від заявленої вартості), грн.</td><td>{item.v_chg.toFixed(2)}</td></tr>
              <tr><td>Додатковий тариф за дооформлення, грн.</td><td>{item.a_chg.toFixed(2)}</td></tr>
              <tr><td>Сума тарифів без ПДВ, грн.</td><td>{(item.b_chg + item.v_chg + item.a_chg).toFixed(2)}</td></tr>
              <tr><td>ПДВ, грн.</td><td>{((item.b_chg + item.v_chg + item.a_chg) * 0.2).toFixed(2)}</td></tr>
              <tr><td>Всього з ПДВ, грн.</td><td>{item.t_chg.toFixed(2)}</td></tr>
            </tbody>
          </table>
        </div>
      </div>

      <hr />
    </>
  )
}

const ViewOrders = (props) => {
  const { isLast } = props

  let growl = React.useRef()
  let d = new Date(); d.setDate(1)

  const [loading, setLoading] = useState(false)
  const [items, setItems] = useState([])
  const [dates, setDates] = useState([d, new Date()])
  React.useEffect(() => { load() }, [])

  const AccordionOrders = props => {
    const [orderno, setOrderno] = useState(null)
    const [openDialogPay, setOpenDialogPay] = useState(false)

    const onPay = e => {
      const orderno = e.target.dataset.orderno
      if (!orderno) return
      setOrderno(orderno)
      setOpenDialogPay(true)
    }

    const onDelete = async e => {
      const orderno = e.target.dataset.orderno
      const orderid = e.target.dataset.orderid
      if (!orderno || !window.confirm("Видалити замовлення № " + orderno)) {
        return
      }

      document.getElementById('shadow').style.visibility = "visible"

      try {
        const response = await fetch(`${SERVER_API}/order/${orderid}`, {
          method: 'DELETE',
          mode: 'cors',
          headers: new Headers({
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('jwt')
          }),
        })
        if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`)
        growl.show({ severity: 'info', summary: 'Повідомлення', detail: `Замовлення № ${orderno} видалено.` })
        load()
        // const json = await response.json()
        // console.log(json)
        // if (json.error) throw Error(json.error)
        // return json
      } catch (err) {
        console.log(err)
        growl.show({ severity: 'error', summary: 'Помилка!', detail: `Помилка виконання` })
      } finally {
        document.getElementById('shadow').style.visibility = "hidden"
      }
    }

    const onEdit = e => {
      const orderid = e.target.dataset.orderid
      window.location = `/order?id=${orderid}`
    }

    const onCopy = async e => {
      const orderno = e.target.dataset.orderno
      const orderid = e.target.dataset.orderid
      if (!orderno || !window.confirm("Копіювати замовлення № " + orderno)) {
        return
      }

      document.getElementById('shadow').style.visibility = "visible"

      try {
        const response = await fetch(`${SERVER_API}/order/copy/${e.target.dataset.orderid}`, {
              method: 'POST',
              mode: 'cors',
              headers: new Headers({
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.getItem('jwt')
              }),
            })
        if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`)
        const json = await response.json()
        const orderno = json.data[0].orderno
        const orderid = json.data[0].id
        growl.show({
          severity: 'success', summary: 'Повідомлення',
          detail: `Замовлення скопійовано.\n№ замовлення ${orderno}`
        })
        window.location = `/order?id=${orderid}`
    } catch (err) {
        console.log(err)
        growl.show({ severity: 'error', summary: 'Помилка!', detail: `Помилка виконання` })
      } finally {
        document.getElementById('shadow').style.visibility = "hidden"
      }
    }

    const tabs = props.items.map(item => {
      return (
        <AccordionTab key={item.id}
          header={`Замовлення №: ${item.orderno}  
                    Дата/час формування: ${item.dt_created.substr(0, 10)} / ${item.dt_created.substr(11, 5)} ` +
            "Статус: " + (item.ttn_id ? 'оброблений' : 'не оброблений') + " / " +
            (item.ispay ? 'сплачений' : 'не сплачений')}>
          <div className="menu">
            {item.ispay ? "" :
              <div className="menu__item">
                <span data-orderno={item.orderno} data-orderid={item.id} onClick={onPay}>
                  <FontAwesomeIcon icon={faMoneyBill} />&nbsp;Сплатити
                </span>
              </div>
            }
            {!item.ttn_id ? "" :
              <div className="menu__item">
                <a href={`${API_URL}/ttn/print?id=${item.ttn_id}&jwt=${localStorage.getItem('jwt')}`} target="_blank">
                  <FontAwesomeIcon icon={faPrint} />&nbsp;ТТН
                </a>
              </div>
            }
            {item.ispay || item.ttn_id ? "" :
              <div className="menu__item">
                <span data-orderno={item.orderno} data-orderid={item.id} onClick={onEdit}>
                  <FontAwesomeIcon icon={faEdit} />&nbsp;Редагувати
                </span>
              </div>
            }
            <div className="menu__item">
              <span data-orderno={item.orderno} data-orderid={item.id} onClick={onCopy}>
                <FontAwesomeIcon icon={faCopy} />&nbsp;Копіювати
              </span>
            </div>
            {item.ispay || item.ttn_id ? "" :
              <div className="menu__item">
                <span data-orderno={item.orderno} data-orderid={item.id} onClick={onDelete}>
                  <FontAwesomeIcon icon={faTrash} />&nbsp;Видалити
                </span>
              </div>
            }
          </div>

          <TabItem item={item} />
        </AccordionTab>
      )
    })

    return (
      <>
        <PayDialog orderno={orderno} visible={openDialogPay} onHide={setOpenDialogPay} />
        <Accordion>{tabs}</Accordion>
      </>
    )

  }


  const load = async () => {
    setLoading(true)
    try {
      const response = await fetch(isLast ?
        `${SERVER_API}/order/?last=10` :
        `${SERVER_API}/order/?date1=${dates[0]}&date2=${dates[1]}`,
        {
          headers: { Authorization: 'Bearer ' + localStorage.getItem('jwt') }
        })
      if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`)
      const json = await response.json()
      console.log(json)
      if (json.error) throw Error(json.error)
      setItems(json.data)
    } catch (err) {
      console.log(err)
      setItems([])
      alert('Error requst to server!')
    } finally {
      setLoading(false)
    }
  }

  const onLoad = e => {
    if (!dates || !dates[1]) {
      growl.show({ severity: 'error', summary: 'Помилка!', detail: "Не вказаний період!" })
      return
    }
    load()
  }

  const calendar = isLast ?
    <h1 className="cabinet-section-title">Останні введені замовлення ...</h1>
    :
    <>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Link to="/"><img src="/img/logo_1.png" alt="logo" className="logo" /></Link>
        <Link to="/order">Створити нове замовлення</Link>
      </div>
      <h1 className="cabinet-section-title">Пергляд введенних замовлень</h1>
      <div style={{ display: "flex", alignItems: "center" }}>
        <span>Період</span>
        <Calendar style={{ margin: "0 5px" }}
          selectionMode="range"
          dateFormat="dd.mm.yy"
          value={dates}
          onChange={e => setDates(e.value)} />
        <a href='#0' onClick={onLoad}><FontAwesomeIcon icon={faCheckCircle} /></a>
      </div>
    </>

  return (
    <div className="cabinet-section">
      <Growl ref={(el) => growl = el}></Growl>
      <div className="container">

        {calendar}

        <div style={{ marginTop: "20px" }}>
          {loading ? <span><FontAwesomeIcon icon={faSpinner} spin />Завантаження ...</span> :
            items.length ? <AccordionOrders items={items} /> :
              <span style={{ color: "gray", fontSize: "2em" }}>Відсутні дані для перегляду</span>}
        </div>
      </div>
    </div>
  )
}


export { ViewOrders }
