import React from 'react'
import { useState, useEffect, useRef } from 'react'
import { SERVER_API } from '../../common/env.js'
import { Toast } from 'primereact/toast'
import Button from '@mui/material/Button'
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import TextField from '@mui/material/TextField'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Box from '@mui/material/Box'
import City from '../Сity.js'

const CityContext = React.createContext()

export default function ManageClients(props) {

  const toast = useRef()

  const [city, setCity] = useState({ country_id: 20, city_id: null })
  const [address, setAddress] = useState('')
  const [companyName, setCompanyName] = useState('')
  const [phone, setPhone] = useState('')
  const [person, setPerson] = useState('')
  const [item, setItem] = useState(null)
  const [selectedItem, setSelectedItem] = useState(null)

  const columns = [
    {
      field: 'actions',
      type: 'actions',
      width: 80,
      getActions: (params) => [
        <GridActionsCellItem
          icon={<EditIcon />}
          label="Редагувати"
          onClick={() => {
            setItem(params.row)
          }}
        />,
        // <GridActionsCellItem
        //   icon={<DeleteIcon />}
        //   label="Видалити"
        //   onClick={async () => {
        //     if (!window.confirm(`Видалити компанію ${params.row.c_co} ?`)) return
        //     const id = params.row.id
        //     try {
        //       const response = await fetch(`${SERVER_API}/clients/${id}`,
        //         {
        //           method: 'DELETE',
        //           headers: new Headers({ Authorization: 'Bearer ' + localStorage.getItem('jwt') })
        //         })
        //       if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`)
        //       const json = await response.json()
        //       toast.current.show({ severity: 'info', summary: '', detail: 'Запис видалено.', life: 3000 });
        //       load()
        //     } catch (err) {
        //       console.log(err)
        //       toast.current.show({ severity: 'error', summary: 'Помилка!', detail: `Помилка виконання` })
        //     } finally {
        //     }
        //   }}
        // />,
      ]
    },
    { field: 'c_co', headerName: 'Компанія', flex: 1 },
    { field: 'full_adrs_ukr', headerName: 'Місто', flex: 1 },
    { field: 'c_adr', headerName: 'Адреса', flex: 1 },
    { field: 'c_name', headerName: 'Контактна особа', flex: 1 },
    { field: 'c_tel', headerName: 'Телефон', flex: 1 },
    { field: 'cacc', headerName: 'CACC', flex: 1 },
  ]

  const [data, setData] = useState([])
  const [openDialog, setOpenDialog] = useState(false)

  const handleClickNewCompany = () => {
    setItem({})
  }

  useEffect(() => {
    (async () => await load())()
  }, [])

  useEffect(() => {
    if (item !== null) {
      setAddress(item.c_adr)
      setCity({country_id: 20, city_id: item.c_cityid})
      setCompanyName(item.c_co)
      setPhone(item.c_tel)
      setPerson(item.c_name)
      setOpenDialog(true)
    }
  }, [item])

  useEffect(() => {
    if (typeof props.setClient === 'function') {
      props.setClient(selectedItem)
    }
  }, [selectedItem])

  const load = async () => {
    try {
      const response = await fetch(`${SERVER_API}/clients`,
        {
          headers: new Headers({ Authorization: 'Bearer ' + localStorage.getItem('jwt') })
        })
      if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`)
      const json = await response.json()
      const data = json.data
      setData(data)
    } catch (err) {
      console.log(err)
      toast.current.show({ severity: 'error', summary: 'Помилка!', detail: `Помилка виконання` })
    } finally {
    }
  }

  const editDialog = () => {
    const handleClickSave = async () => {
      if (!city.city_id) {
        return alert('Не вказаний населений пункт!')
      }
      try {
        const response =
          Object.keys(item).length === 0 ?
            await fetch(`${SERVER_API}/clients`,
              {
                method: 'POST',
                headers: new Headers({
                  Accept: 'application/json',
                  'Content-Type': 'application/json',
                  Authorization: 'Bearer ' + localStorage.getItem('jwt')
                }),
                body: JSON.stringify({
                  phone,
                  company_name: companyName,
                  address,
                  city: city.city_id,
                  person,
                })
              }
            ) :
            await fetch(`${SERVER_API}/clients/${item.id}`,
              {
                method: 'PUT',
                headers: new Headers({
                  Accept: 'application/json',
                  'Content-Type': 'application/json',
                  Authorization: 'Bearer ' + localStorage.getItem('jwt')
                }),
                body: JSON.stringify({
                  phone,
                  company_name: companyName,
                  address,
                  city: city.city_id,
                  person,
                })
              }
            )

        if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`)
        load()
        toast.current.show({ severity: 'success', summary: 'Повідомлення', detail: `Дані оновлено.` })
        setOpenDialog(false)
      } catch (err) {
        console.log(err)
        toast.current.show({ severity: 'error', summary: 'Помилка!', detail: `Помилка виконання` })
      } finally {
      }
    }

    const handleCloseDialog = () => {
      setOpenDialog(false)
      setItem(null)
    }
    
    return (
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Редагування</DialogTitle>
        <DialogContent>
          <TextField autoFocus label="CACC"
            margin="dense"
            fullWidth
            variant="outlined"
            value={item?.cacc || ''}
            disabled
          />
          <TextField autoFocus label="Компанія"
            margin="dense"
            fullWidth
            variant="outlined"
            value={companyName}
            onChange={e => setCompanyName(e.target.value)}
          />
          <City context={CityContext} />
          <TextField label="Адреса"
            margin="dense"
            fullWidth
            variant="outlined"
            value={address}
            onChange={e => setAddress(e.target.value)}
          />
          <TextField label="Контактна особа"
            margin="dense"
            fullWidth
            variant="outlined"
            value={person}
            onChange={e => setPerson(e.target.value)}
          />
          <TextField label="Телефон"
            margin="dense"
            fullWidth
            variant="outlined"
            value={phone}
            onChange={e => setPhone(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClickSave}>Зберегти</Button>
          <Button onClick={handleCloseDialog}>Відмінити</Button>
        </DialogActions>
      </Dialog>
    )
  }


  return (
    <>
      <CityContext.Provider value={[city, setCity]}>
        <Toast ref={toast} />
        <Box>
          <h1>ManageClients</h1>
          <Button variant="text" onClick={handleClickNewCompany}>Додати</Button>
          <DataGrid autoHeight rows={data} columns={columns}
            onSelectionModelChange={
              value => setSelectedItem(data.find(item => item.id == value))
            }
          />
          {editDialog()}
        </Box>
      </CityContext.Provider>
    </>
  )
}
