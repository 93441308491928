import React from 'react'
import { Menubar } from 'primereact/menubar';


export default function Menu() {

  const items = [
    {
      label: 'ТТН',
      items: [
        {
          label: 'Перегляд',
          // icon: 'pi pi-fw pi-plus',
          command: () => { window.location = '/ent/ttn/view' }
        },
        {
          label: 'Додати',
          // icon: 'pi pi-fw pi-plus',
          command: () => { window.location = '/ent/ttn' }
        },
      ]
    },
    {
      label: 'Налаштування',
      items: [
        {
          label: 'Користувачі особистого кабінету',
          // icon: 'pi pi-fw pi-plus',
          command: () => { window.location = '/ent/admin/users' }
        },
        {
          label: 'Платник',
          // icon: 'pi pi-fw pi-plus',
          command: () => { window.location = '/ent/admin/payer' }
        },
      ]
    },
    {
      label: 'Звіти',
      items: [
        {
          label: 'Відправлення за період',
          // icon: 'pi pi-fw pi-plus',
          command: () => { alert('Відправлення за період') }
        },
        {
          label: 'Баланс по взаєморозрахункам',
          // icon: 'pi pi-fw pi-eye',
          command: () => { alert('Баланс по взаєморозрахункам') }
        },
      ]
    },
    {
      label: 'Мої клієнти',
      // icon: 'pi pi-fw',
      command: () => window.location = '/ent/client'
    },
    {
      label: 'Вихід',
      icon: 'pi pi-fw pi-power-off',
      command: () => {
        localStorage.clear()
        window.location = '/login'
      }
    },
  ]


  return (
    <Menubar model={items} />
  )

}
