import ReactDOM from 'react-dom'
import React, { useState, useEffect, useContext, useRef } from 'react'
import { InputText } from 'primereact/inputtext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
// import { Button } from 'primereact/button'
import { SERVER_API } from '../common/env'
import '../scss/autocomplete.scss'
import TextField from '@mui/material/TextField'

const LIST_SIZE = 5


export default function Country(props) {
  const [context, setContext] = useContext(props.context)

  const [isLoading, setIsLoading] = useState(false)
  const [searchString, setSearchString] = useState('')
  const [item, setItem] = useState(null)
  const [items, setItems] = useState([])
  const [page, setPage] = useState(0)
  const [inputSearchName] = useState('search-country-input' + Math.trunc(Math.random() * 100))

  let refWrapperList = useRef()
  let refSelect = useRef()

  useEffect(() => {
    // initiate the event handler
    document.addEventListener('click', onDocumentClick, false)
    // this will clean up the event every time the component is re-rendered
    return () => {
      document.removeEventListener('click', onDocumentClick)
    }
  })

  useEffect(() => {
    (async () => {
      if (context.country_id) {
        try {
          setIsLoading(true)
          const response = await fetch(`${SERVER_API}/countries/${context.country_id}`,
            {
              headers: new Headers({ Authorization: 'Bearer ' + localStorage.getItem('jwt') })
            })
          if (!response.ok) throw Error('Error: country fetch data')
          const json = await response.json()
          const data = json.data
          if (data) {
            const data = json.data
            setItems(items => items.concat(data))
            data.forEach(e => {
              let option = document.createElement('option')
              option.value = e.city_id
              option.text = getTextList(e)
              refSelect.current.appendChild(option)
            })
            setItem(data[0])
          }
          setSearchString(getTextList(json.data[0]))
          refWrapperList.current.style.display = "blok"
          document.getElementById(inputSearchName).focus()
        } catch (error) {
          console.log(error.message)
          alert(error.message)
          setItems([])
          refWrapperList.current.style.display = "none"
          throw Error(error.message)
        } finally {
          setIsLoading(false)
        }
      }
    })();
  }, [])

  useEffect(() => {
    getTextList(item)
  }, [item])

  const loadData = () => {
    if (searchString === '' || isLoading) return
    setIsLoading(true)

    fetch(`${SERVER_API}/countries/?filter=${searchString}&page=${page}`,
      {
        headers: new Headers({ Authorization: 'Bearer ' + localStorage.getItem('jwt') })
      })
      .then(data => { return data.json() })
      .then(json => {
        if (json.data) {
          const data = json.data
          setItems(items => items.concat(data))
          data.forEach(e => {
            let option = document.createElement('option')
            option.value = e.city_id
            option.text = getTextList(e)
            refSelect.current.appendChild(option)
          })
        }
        refWrapperList.current.style.display = "blok"
        setIsLoading(false)
        document.getElementById(inputSearchName).focus()
      })
      .catch(e => {
        console.error(e)
        setItems([])
        refWrapperList.current.style.display = "none"
        setIsLoading(false)
      })
  }

  const onChangeInput = e => {
    setSearchString(e.currentTarget.value.trim())
  }

  const doSelectedItem = (index) => {
    if (index < 0 || index > items - 1) return
    let item = items[index]
    let data = Object.assign({}, context)
    data.city_id = item.city_id
    setContext(data)
    setSearchString(getTextList(item))
  }

  const onClickList = e => {
    let i = e.currentTarget.selectedIndex
    doSelectedItem(i)
    refWrapperList.current.style.display = "none"
  }

  const onKeyPressList = e => {
    if (e.key === 'Enter') {
      onClickList(e)
    }
  }

  const onKeyPressInput = e => {
    if (!items.length) return
    let i = refSelect.current.selectedIndex
    switch (e.key) {
      case 'ArrowUp':
        i -= i > 0 ? 1 : 0
        break
      case 'ArrowDown':
        i += i < items.length ? 1 : 0
        break
      case 'Enter':
        doSelectedItem(i)
        refWrapperList.current.style.display = "none"
        break
      case 'Escape':
        // setSearchString(getTextList(city))
        refWrapperList.current.style.display = "none"
        break
    }
    refSelect.current.selectedIndex = i
  }

  const onScrollList = event => {
    if (isLoading) return
    let e = event.target
    if (e.scrollHeight - e.scrollTop === e.clientHeight) {
      setPage(page => page + 1)
      loadData()
    }
  }

  const getTextList = e => {
    return e ? e.country_ukr_name.trim() : null
  }

  const onDocumentClick = e => {
    // setSearchString(getTextList(city))
    if (e.target.id !== inputSearchName)
      if (refWrapperList)
        refWrapperList.current.style.display = 'none'
  }


  const selectList = (
    <div className="autocomplete-list" style={{ display: "none" }} ref={refWrapperList}>
      <div>
        <select size={LIST_SIZE}
          ref={refSelect}
          onClick={onClickList}
          onKeyPress={onKeyPressList}
          onScroll={onScrollList}>
        </select>
      </div>
    </div>
  )


  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <FontAwesomeIcon icon={faSpinner} spin style={{ visibility: isLoading ? "" : "hidden" }} />
      &nbsp;
      <div className="autocomplete-box" style={{ width: "100%" }}>
        <div className="autocomplete">
          <span className='p-float-label' style={{ width: "100%" }}>
            <InputText id={inputSearchName}
              disabled={true}
              autoComplete="off"
              value={searchString}
              onChange={onChangeInput}
              onKeyDown={onKeyPressInput}
              onClick={e => refWrapperList.current.style.display = "block"} />
            <label htmlFor={inputSearchName}>Країна</label>
          </span>
        </div>
        <div>
          {selectList}
        </div>
      </div>
    </div>
  )
}
