import React from 'react'
import { Navigate } from 'react-router-dom'
import Menu from './Menu'
import { ViewOrders } from './order/vieworders'
import Container from '@mui/material/Container'


export default function Main() {
  React.useEffect(() => {
    document.title = `EXMOTO: заказы [${localStorage.getItem('login')}]`
  })
  if (!localStorage.getItem('jwt')) {
    return <Navigate to="/login" />
  } else {
    return (
      <div className="content">
        <div className="wrapper">
          <div className="nav p-d-flex">
           <a href="https://exmoto.com"><img src="/img/logo_1.png" alt="" /></a>
            <Menu />
          </div>
          <ViewOrders isLast={true} />           
        </div>
      </div>
    )
  }
}
