import React, { useState, useEffect, useContext, useRef } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { SERVER_API } from '../../common/env'
import '../../scss/calccost.scss'
import { ParamsContext, SenderContext, ReceiverContext } from '../../common/data'


export default function Calccost(props) {
  const [params, setParams] = useContext(ParamsContext)
  const [sender, setSender] = useContext(SenderContext)
  const [receiver, setReceiver] = useContext(ReceiverContext)

  const [cost, setCost] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    doCalcCoast()
  }, [sender.city_id, receiver.city_id,
  params.weight, params.service,
  params.cargo, params.place,
  params.cost
  ])

  const doCalcCoast = () => {
    if (!sender.city_id || !receiver.city_id) return

    setIsLoading(true)
    let data = {
      org: sender.city_id,
      dst: receiver.city_id,
      firmid: 0,
      planno: 22,
      usewt: params.weight,
      tsrv: params.service,
      adate: null,
      tpak: params.cargo,
      pcs: params.place,
      cost: params.cost
    }

    fetch(`${SERVER_API}/calccost`, {
      method: 'POST',
      mode: 'cors',
      headers: new Headers({
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('jwt')
      }),
      body: JSON.stringify({ data: data }),
    })
      .then(response => {
        try {
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          return response.json()
        } finally {
          setIsLoading(false)
        }
      })
      .then(json => {
        const data = json.data
        setCost(data)
        setIsLoading(false)
      })
      .catch(error => {
        console.log(error)
        setCost(null)
        setIsLoading(false)
      });
  }


  const tableCost = () => {
    if (cost) {
      return (
        <div className="wrapper-table-cost">
          <table className="table-cost">
            <tbody>
              <tr><td>Основной (базовый) тариф без НДС, грн.</td><td>{cost.B_chg.toFixed(2)}</td></tr>
              <tr><td>Оценочный тариф ({cost.PROC_ESTIMATED_TARIFF}% от заявленной стоимости), грн.</td><td>{cost.V_chg.toFixed(2)}</td></tr>
              <tr><td>Дополнительный тариф за дооформление, грн.</td><td>{cost.A_chg.toFixed(2)}</td></tr>
              <tr><td>Сумма тарифов без НДС, грн.</td><td>{cost.T_chg.toFixed(2)}</td></tr>
              <tr><td>НДС, грн.</td><td>{(cost.T_chg * 0.2).toFixed(2)}</td></tr>
              <tr><td>Итого с НДС, грн.</td><td>{(cost.T_chg * 1.2).toFixed(2)}</td></tr>
            </tbody>
          </table>
        </div>
      )
    }
  }


  return (
    <div>
      <span style={{ fontWeight: 'bold' }}>Вартість доставки:</span>
      &nbsp;
      {isLoading ? <FontAwesomeIcon icon={faSpinner} spin /> : ''}
      {cost == null ? '...' : tableCost()}
    </div>
  )

}