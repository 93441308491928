import React from 'react'
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"
// import IdleTimerContainer from './common/IdleTimerContainer'
import Main from './component/Main'
import SignIn from './component/SignIn'
import Order from './component/order/order'
import { ViewOrders } from './component/order/vieworders'
// import Ttn from './component/ttn/ttn'
import ViewTtn from './component/ttn/viewttn'
// import PrintTtn from './component/ttn/printttn'
import MainEnt from './component/ent/MainEnt.js'
import ManageClients from './component/ent/ManageClients.js'
import Ttn from './component/ent/ttn/Ttn.js'
import { default as ViewTtnEnt } from './component/ent/ttn/ViewTtn.js'
import { default as AdminUsers } from './component/ent/admin/Users.js'
import { default as Payer } from './component/ent/admin/Payer.js'



export default function App() {
  return (
    <Router>
      <Routes>
        <Route path='/' element={<Main />} />
        <Route path='/login' element={<SignIn />} />
        <Route path='/order' element={<Order />} />
        <Route path='/order/view' element={<ViewOrders />} />
        {/* <Route path='/ttn' element={<Ttn />} /> */}
        <Route path='/ttn/view' element={<ViewTtn />} />
        {/*  <Route path='/ttn/view/:id' element={<PrintTtn />} /> */}
         <Route path='/ent' element={<MainEnt />} />
         <Route path='/ent/client' element={<ManageClients />} />
         <Route path='/ent/ttn' element={<Ttn />} />
         <Route path='/ent/ttn/view' element={<ViewTtnEnt />} />
         <Route path='/ent/admin/users' element={<AdminUsers />} />
         <Route path='/ent/admin/payer' element={<Payer />} />
        </Routes>
    </Router>
  )
}
