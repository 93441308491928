import React, { useEffect, useState, useRef, useContext } from 'react'
import { Navigate } from 'react-router-dom'
import { Toast } from 'primereact/toast'
import { Button } from 'primereact/button'
import Address from './address'
import Params from './params'
import Calccost from './calccost'
import { SERVER_API } from '../../common/env'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import '../../scss/order.scss'
import { PayDialog } from './paydialog'
import { SenderContext, ReceiverContext, initPointer, ParamsContext, initParams } from '../../common/data'


export default function Order(props) {
  const [sender, setSender] = useState(initPointer)
  const [receiver, setReceiver] = useState(initPointer)
  const [params, setParams] = useState(initParams)

  const [orderId, setOrderId] = useState(null)
  const [orderNo, setOrderNo] = useState(null)
  const [inSaved, setInSaved] = useState(false)
  const [isPayment, setIsPayment] = useState(false)
  const [visibleDialogIPay, setVisibleDialogIPay] = useState(false)

  const growl = useRef()

  useEffect(() => {

    document.title = `EXMOTO: нове замовлення [${localStorage.getItem('login')}]`
    const q = new URLSearchParams(window.location.search)
    const id = q.get('id')
    if (id) {
      fetch(`${SERVER_API}/order/${id}`, {
        method: 'GET',
        mode: 'cors',
        headers: new Headers({ Authorization: 'Bearer ' + localStorage.getItem('jwt') })
      })
        .then(response => {
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          return response.json()
        })
        .then(json => {
          if (json.data) {
            const data = json.data[0]
            document.title = `EXMOTO: замовлення # ${data.orderno}`
            setOrderId(data.id)
            setOrderNo(data.orderno)
            setIsPayment(data.ispay)
            let value
            // sender
            value = Object.assign({}, sender)
            value.country_id = data.sender_country_id
            value.city_id = data.sender_city_id
            value.street = data.sender_street_name
            value.bldg = data.sender_bldg
            value.flat = data.sender_flat
            value.client = data.sender_client_name
            value.contact = data.sender_contact_name
            value.phone = data.sender_phone
            value.email = data.sender_email
            setSender(value)
            // receiver
            value = Object.assign({}, receiver)
            value.country_id = data.receiver_country_id
            value.city_id = data.receiver_city_id
            value.street = data.receiver_street_name
            value.bldg = data.receiver_bldg
            value.flat = data.receiver_flat
            value.client = data.receiver_client_name
            value.contact = data.receiver_contact_name
            value.phone = data.receiver_phone
            value.email = data.receiver_email
            // value.contact = data.receiver_contact
            value.phone = data.receiver_phone
            setReceiver(value)
            // parameters
            value = Object.assign({}, params)
            value.cargo = data.cargo_id
            value.service = data.service_id
            value.delivery = data.delivery_id
            value.attachment = data.attachment_id
            value.payer = data.payer_id
            value.payment = data.payment_id
            value.cost = data.cost
            value.place = data.place_count
            value.weight = data.weight
            value.note = data.note
            setParams(value)
          } else {
            growl.current.show({ severity: 'error', summary: 'Помилка', detail: "Замовлення не знайдено!" })
          }
        })
        .catch(error => {
          growl.current.show({ severity: 'error', summary: 'Ошибка', detail: "Замовлення не знайдено!" })
          console.log(error.response)
          setInSaved(false)
        })
    } else {
      // initData(null)
    }
  }, [])

  const onClickSave = e => {
    if (!sender.country_id ||
      !sender.city_id ||
      !sender.street ||
      !sender.bldg ||
      !sender.client ||
      !sender.contact ||
      !sender.phone) {
      growl.current.show({ severity: 'error', summary: 'Помилка!', detail: "Не вказано данні відправника!" })
      return
    }
    if (!receiver.country_id ||
      !receiver.city_id ||
      !receiver.street ||
      !receiver.bldg ||
      !receiver.client ||
      !receiver.contact ||
      !receiver.phone) {
      growl.current.show({ severity: 'error', summary: 'Помилка!', detail: "Не вказано данні отримувача!" })
      return
    }
    /* ********************************** */
    setInSaved(true)
    const data = {
      id: orderId,
      orderno: orderNo,
      sender: sender,
      receiver: receiver,
      params: params
    }
    fetch(`${SERVER_API}/order`, {
      method: 'POST',
      mode: 'cors',
      headers: new Headers({
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('jwt')
      }),
      body: JSON.stringify({ data: data }),
    })
      .then(response => {
        console.log(response)
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json()
      })
      .then(json => {
        const data = json.data[0]
        growl.current.show({ severity: 'success', summary: '', detail: `Данні збережено!\nНомер замовлення ${data.orderno}` })
        setOrderId(data.id)
        setOrderNo(data.orderno)
        // window.scroll({ top: 0, left: 0, behavior: 'smooth' })
        setInSaved(false)
      })
      .catch(error => {
        growl.current.show({ severity: 'error', summary: 'Помилка', detail: "Помилка виконання!" })
        console.log(error.response)
        setInSaved(false)
      })
  }


  const onClickNewOrder = e => {
    window.location = 'order'
  }


  const onHideDialogIPay = (e) => {
    setVisibleDialogIPay(false)
  }

  const onShowDialogIPay = (e) => {
    setVisibleDialogIPay(true)
  }


  if (!localStorage.getItem('jwt')) {
    return <Navigate to="/login" />
  } else {
    return (
      <div className='content'>

        <PayDialog orderno={orderNo} visible={visibleDialogIPay} onHide={onHideDialogIPay} />

        <Toast ref={growl} />
        <div className="wrapper">

          <a href="/">
            <img src="/img/logo_1.png" alt="" style={{ width: "150px" }} />
          </a>

          <h1>Замовлення {orderNo ? " № " + orderNo : ""}</h1>

          <SenderContext.Provider value={[sender, setSender]}>
            <ReceiverContext.Provider value={[receiver, setReceiver]}>
              <ParamsContext.Provider value={[params, setParams]}>
                <div data-title="Відправник" className="box">
                  <Address context={SenderContext} //isReadOnly={this.state.orderno != null}
                  />
                </div>

                <div data-title="Отримувач" className="box">
                  <Address context={ReceiverContext} //isReadOnly={this.state.orderno != null}
                  />
                </div>

                <div data-title="Параметри" className="box">
                  <Params />
                </div>

                <div style={{ marginTop: "1em" }}>
                  <Calccost />
                </div>
              </ParamsContext.Provider>
            </ReceiverContext.Provider>
          </SenderContext.Provider>

          <div className="footer" style={{ paddingBottom: "50px" }}>
            {inSaved ?
              <span><FontAwesomeIcon icon={faSpinner} spin />&nbsp;Сохранение ...</span> :
              <div>
                <Button label="Зберегти" onClick={onClickSave}
                />
                &nbsp;&nbsp;
                <Button label="Сплатити" onClick={onShowDialogIPay}
                  disabled={isPayment}
                />
                &nbsp;&nbsp;
                <Button label="Ввод нового замовлення" onClick={onClickNewOrder}
                />
              </div>
            }
          </div>

        </div>
      </div>
    )
  }

}

