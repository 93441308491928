import React from 'react'
import { useState, useEffect, useContext } from 'react'
import { Dropdown } from 'primereact/dropdown'
import { InputNumber } from 'primereact/inputnumber'
import { InputTextarea } from 'primereact/inputtextarea'
import { ParamsContext, CARGO, SERVICE, DELIVERY, ATTACHMENT, PAYMENT, PAYER, WEGHT_LE, WEGHT_PL } from '../../common/data'
import '../../scss/order.scss'



export default function Params(props) {
  const [context, setContext] = useContext(ParamsContext)

  const inputWeight = () => {
    if ((context.cargo == 'LE') || ((context.cargo == 'PL'))) {
      return (
        <div className="p-field p-col-6 p-md-3">
          <span className="p-float-label">
            <Dropdown
              disabled={props.isReadOnly}
              value={context.weight}
              options={context.cargo == 'LE' ? WEGHT_LE : WEGHT_PL}
              optionValue="value"
              optionLabel="name"
              onChange={e => {
                let data = Object.assign({}, context);
                data.weight = e.value
                setContext(data)
              }}
            />
            <label htmlFor="Вага, кг">Вага, кг</label>
          </span>
        </div>
      )
    } else {
      return (
        <div className="p-field p-col-4 p-md-2">
          <span className="p-float-label">
            <InputNumber value={context.weight} mode="decimal" minFractionDigits={2} min={10} max={99999.99}
              disabled={props.isReadOnly}
              onChange={e => {
                let data = Object.assign({}, context);
                data.weight = e.value
                setContext(data)
              }}
            />
            <label htmlFor="Вес, кг">Вес, кг</label>
          </span>
        </div>
      )
    }
  }


  return (
    <>
      <div className="p-fluid p-grid">

        <div className="p-field p-col-6 p-md-3">
          <span className="p-float-label">
            <Dropdown id="Сервіс"
              disabled={props.isReadOnly}
              value={context.service}
              options={SERVICE}
              optionValue="value"
              optionLabel="name"
              onChange={e => {
                let data = Object.assign({}, context);
                data.service = e.value
                setContext(data)
              }}
            />
            <label htmlFor="Сервіс">Сервіс</label>
          </span>
        </div>

        <div className="p-field p-col-6 p-md-3">
          <span className="p-float-label">
            <Dropdown id="Формат"
              disabled={props.isReadOnly}
              value={context.cargo}
              options={CARGO}
              optionValue="value"
              optionLabel="name"
              onChange={e => {
                let weight = 0
                switch (e.value) {
                  case 'LE': weight = .2; break;
                  case 'PL': weight = 1; break;
                  default: weight = 10
                }
                let data = Object.assign({}, context);
                data.cargo = e.value
                data.weight = weight
                setContext(data)
              }} />
            <label htmlFor="Формат">Формат</label>
          </span>
        </div>

        <div className="p-field p-col-4 p-md-2">
          <span className="p-float-label">
            <InputNumber value={context.place} min={1} max={1000} showButtons
              disabled={props.isReadOnly}
              onValueChange={e => {
                let data = Object.assign({}, context);
                data.place = e.value
                setContext(data)
              }}
            />
            <label htmlFor="Кількість місць">Кількість місць</label>
          </span>
        </div>

        {inputWeight()}

        <div className="p-field p-col-6 p-md-3">
          <span className="p-float-label">
            <Dropdown id="Доставка"
              disabled={props.isReadOnly}
              value={context.delivery}
              options={DELIVERY}
              optionValue="value"
              optionLabel="name"
              onChange={e => {
                let data = Object.assign({}, context);
                data.delivery = e.value
                setContext(data)
              }}
            />
            <label htmlFor="Доставка">Доставка</label>
          </span>
        </div>

        <div className="p-field p-col-6 p-md-3">
          <span className="p-float-label">
            <Dropdown id="Тип_корреспонденции"
              disabled={props.isReadOnly}
              value={context.attachment}
              options={ATTACHMENT}
              optionValue="value"
              optionLabel="name"
              onChange={e => {
                let data = Object.assign({}, context);
                data.attachment = e.value
                setContext(data)
              }}
            />
            <label htmlFor="Тип_корреспонденции">Тип корреспонденції</label>
          </span>
        </div>

        <div className="p-field p-col-4 p-md-2">
          <span className="p-float-label">
            <InputNumber value={context.cost} mode="decimal" minFractionDigits={2} min={0.02} max={99999.99}
              disabled={props.isReadOnly}
              onChange={e => {
                let data = Object.assign({}, context);
                data.cost = e.value
                setContext(data)
              }}
            />
            <label>Оціночна вартість, грн.</label>
          </span>
        </div>

        <div className="p-field p-col-6 p-md-3">
          <span className="p-float-label">
            <Dropdown id="Платник"
              disabled={props.isReadOnly}
              value={context.payer}
              options={PAYER}
              optionValue="value"
              optionLabel="name"
              onChange={e => {
                let data = Object.assign({}, context);
                data.payer = e.value
                setContext(data)
              }}
            />
            <label htmlFor="Платник">Платник</label>
          </span>
        </div>

        <div className="p-field p-col-6 p-md-3">
          <span className="p-float-label">
            <Dropdown id="Спосіб_оплати"
              disabled={props.isReadOnly}
              value={context.payment}
              options={PAYMENT}
              optionValue="value"
              optionLabel="name"
              onChange={e => {
                let data = Object.assign({}, context);
                data.payment = e.value
                setContext(data)
              }}
            />
            <label htmlFor="Спосіб_оплати">Спосіб оплати</label>
          </span>
        </div>

      </div>

      <div style={{ width: "100%" }}>
        <label style={{ color: "#898989", fontSize: "12px" }}>Примітка</label>
        <InputTextarea style={{ width: "100%" }} rows={3} cols={30}
          value={context.note || ''}
          onChange={e => {
            let data = Object.assign({}, context);
            data.note = e.target.value
            setContext(data)
          }}
        />
      </div>

    </>
  )
}
