import { React, useState, useRef,} from 'react'
import WhoIs from './WhoIs'
import Params from './Params'
import Cost from './Cost'
import { Button, Box } from '@mui/material'
import * as Context from './Context'
import { API_URL, SERVER_API } from '../../../common/env.js'
import { Toast } from 'primereact/toast'


export default function Ttn(props) {
  const [sender, setSender] = useState(null)
  const [receiver, setReceiver] = useState(null)
  const [params, setParams] = useState(Context.InitParams)
  const [isLoaded, setIsLoaded] = useState(false)
  const [isSaved, setIsSaved] = useState(false)

  const toast = useRef()

  const handleSave = async () => {
    setIsLoaded(true)
    try {
      const response =
        await fetch(`${SERVER_API}/ttn/ent`,
          {
            method: 'POST',
            headers: new Headers({
              Accept: 'application/json',
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + localStorage.getItem('jwt')
            }),
            body: JSON.stringify({
              sender: {
                id: sender.id,
              },
              receiver: {
                id: receiver.id,
              },
              params: {
                cargo: params.cargo.value,
                service: params.service.value,
                cost: params.cost,
                place: params.place,
                weight: params.weight,
                note: params.note,
                dptnp: params.dptnp,
              }
            })
          }
        )

      if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`)
      const json = await response.json()
      const data = json.data
      const id = data[0].id
      window.open(`${API_URL}/ttn/print/ent?id=${id}&jwt=${localStorage.getItem('jwt')}`)
      toast.current.show({ severity: 'success', summary: 'Повідомлення', detail: `Дані оновлено.` })
      setIsSaved(true)
    } catch (err) {
      console.log(err)
      toast.current.show({ severity: 'error', summary: 'Помилка!', detail: `Помилка виконання` })
    } finally {
    }
  }


  return (
    <>
      <Toast ref={toast} />
      <Context.SenderContext.Provider value={[sender, setSender]}>
        <Context.ReceiverContext.Provider value={[receiver, setReceiver]}>
          <Context.ParamsContext.Provider value={[params, setParams]}>
            <WhoIs whois={1} context={Context.SenderContext} />
            <WhoIs whois={2} context={Context.ReceiverContext} />
            <Params />
            {/* <Cost /> */}
            <hr />
            {isSaved ? 
              <Box sx={{display: 'flex'}}>
                <h4>Дані збережено</h4>
                <Button onClick={e => {
                    setIsLoaded(false)
                    setIsSaved(false)
                  }}>Нова ТТН</Button> 
              </Box> : 
              <Button onClick={handleSave} disabled={isLoaded}>Зберегти</Button>
            }
          </Context.ParamsContext.Provider>
        </Context.ReceiverContext.Provider>
      </Context.SenderContext.Provider>
    </>
  )
}