import React from 'react'
import Button from '@mui/material/Button'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import MenuList from '@mui/material/MenuList'
import ListItemText from '@mui/material/ListItemText'
import ListItemIcon from '@mui/material/ListItemIcon'
import ContentCut from '@mui/icons-material/ContentCut'
import ContentCopy from '@mui/icons-material/ContentCopy'
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown'


export default function BasicMenu() {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)
  const handleClick = event => { setAnchorEl(event.currentTarget) }
  const handleClose = () => { setAnchorEl(null) }

  return (
    <div>
      <MenuList autoFocus='true'>

      <Button onClick={handleClick} endIcon={<KeyboardArrowDown />}>Замовлення</Button>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem onClick={e => {handleClose()}}>
          <ListItemIcon>
            <ContentCut fontSize="small" />
          </ListItemIcon>
          <ListItemText>Створити</ListItemText>
        </MenuItem>
        <MenuItem onClick={e => {handleClose()}}>
          <ListItemIcon>
            <ContentCopy fontSize="small" />
          </ListItemIcon>
          <ListItemText>Перегляд</ListItemText>
        </MenuItem>
      </Menu>

      <Button onClick={handleClick}>Замовлення</Button>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem onClick={e => {handleClose()}}>
          <ListItemIcon>
            <ContentCut fontSize="small" />
          </ListItemIcon>
          <ListItemText>Створити</ListItemText>
        </MenuItem>
        <MenuItem onClick={e => {handleClose()}}>
          <ListItemIcon>
            <ContentCopy fontSize="small" />
          </ListItemIcon>
          <ListItemText>Перегляд</ListItemText>
        </MenuItem>
      </Menu>
      </MenuList>

    </div>
  )
}


















// import React from 'react'
// import { Menubar } from 'primereact/menubar'

// export default function Menu() {

//   const logout = () => {
//     localStorage.clear()
//     window.location = '/login'
//   }

//   const items = [
//     {
//       label: 'Замовлення',
//       items: [
//         {
//           label: 'Створити',
//           icon: 'pi pi-fw pi-plus',
//           command: () => { window.location = "/order" }
//         },
//         {
//           label: 'Перегляд',
//           icon: 'pi pi-fw pi-eye',
//           command: () => { window.location = "/order/view" }
//         },
//       ]
//     },
//     {
//       label: 'ТТН',
//       items: [
//         // {
//         //     label: 'Створити',
//         //     icon: 'pi pi-fw pi-plus',
//         //     command: () => {window.location = "/ttn"}
//         // },
//         {
//           label: 'Перегляд',
//           icon: 'pi pi-fw pi-eye',
//           command: () => { window.location = "/ttn/view" }
//         },
//       ]
//     },
//     {
//       label: 'Вихід',
//       icon: 'pi pi-fw pi-power-off',
//       command: logout
//     }
//   ]


//   return (
//     <Menubar model={items} />
//   )

// }
