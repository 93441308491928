import React from 'react'
import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import CssBaseline from '@mui/material/CssBaseline'
import TextField from '@mui/material/TextField'
import Link from '@mui/material/Link'
import Box from '@mui/material/Box'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { Radio, FormControl, RadioGroup, FormControlLabel } from '@mui/material'
import { useState } from 'react'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import { API_URL } from '../common/env.js'


const LOGIN_INDIVIDUAL = 1
const LOGIN_ENTERPRISE = 2
const theme = createTheme()


const Copyright = props => {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://exmoto.com/" target={"_blank"}>
        ТОВ "EXMOTO"
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  )
}


const LoginIndividual = props => {
  const [phone, setPhone] = useState('')
  const [sms, setSms] = useState('')
  const [open, setOpen] = useState(false)

  const handleCancelModal = () => { setOpen(false) }

  const handleOkModal = async () => {
    try {
      const response = await fetch(API_URL + '/api/v1/auth/sms',
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ phone, code: sms }),
        })
      if (response.ok) {
        const json = await response.json()
        console.log(json)
        if (json.error) {
          throw Error(json.error)
        }
        localStorage.setItem('jwt', json.token)
        localStorage.setItem('phone', phone)
        localStorage.setItem('login', phone)
        window.location = '/'
      } else {
        throw Error('Error requst to server!')
      }
      setOpen(false)
    } catch (err) {
      console.log(err)
      alert('Error requst to server!')
    }
  }

  const handleSubmit = async event => {
    event.preventDefault()
    const data = new FormData(event.currentTarget)
    try {
      const response = await fetch(API_URL + '/api/v1/auth',
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ phone }),
        })
      if (response.ok) {
        const json = await response.json()
        if (json.error) throw Error(json.error)
      } else {
        throw Error('Error requst to server!')
      }
      setOpen(true)
    } catch (err) {
      console.log(err)
      alert('Error requst to server!')
    }
  }

  const dialog = (
    <Dialog open={open}>
      <DialogContent>
        <DialogContentText>
          Введіть код SMS.
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          fullWidth
          variant="standard"
          value={sms}
          onInput={e => setSms(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleOkModal}>Ок</Button>
        <Button onClick={handleCancelModal}>Відмінити</Button>
      </DialogActions>
    </Dialog>
  )


  return (
    <>
      {dialog}
      <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }} {...props} >
        <TextField
          margin="normal"
          required
          fullWidth
          id="phone"
          label="Телефон"
          name="phone"
          autoComplete="Phone"
          autoFocus
          value={phone}
          onInput={e => setPhone(e.target.value)}
        />
        <Button type="submit" disabled={phone.trim() === ''} fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>Вхід</Button>
      </Box>
    </>
  )
}

const LoginEnterprise = props => {
  const [enterprise, setEnterprise] = useState('') //useState('39440477')
  const [phone, setPhone] = useState('')
  const [sms, setSms] = useState('')
  const [open, setOpen] = useState(false)
  const handleCancelModal = () => { setOpen(false) }

  const handleOkModal = async () => {
    try {
      const response = await fetch(API_URL + '/api/v1/auth/sms',
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ enterprise, phone, code: sms }),
        })
      
      
      if (response.ok) {
        const json = await response.json()
        console.log(json)
        if (json.error) {
          throw Error('Помилка аутентифікації')
        }
        localStorage.setItem('jwt', json.token)
        localStorage.setItem('enterprise', json.token)
        localStorage.setItem('phone', phone)
        window.location = '/ent'
      } else {
        throw Error('Помилка аутентифікації!')
      }
      setOpen(false)
    } catch (err) {
      console.log(err)
      alert(err.message)
    }
  }

  const handleSubmit = async event => {
    event.preventDefault()
    const data = new FormData(event.currentTarget)
    try {
      const response = await fetch(API_URL + '/api/v1/auth',
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ enterprise, phone }),
        })
      if (response.ok) {
        const json = await response.json()
        console.log(json)
        if (json.error) throw Error('Помилка аутентифікації!')
      } else {
        throw Error('Error requst to server!')
      }
      setOpen(true)
    } catch (err) {
      console.log(err)
      alert(err.message)
    }
  }

  const dialog = (
    <Dialog open={open}>
      <DialogContent>
        <DialogContentText>
          Введіть код SMS.
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          fullWidth
          variant="standard"
          value={sms}
          onInput={e => setSms(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleOkModal}>Ок</Button>
        <Button onClick={handleCancelModal}>Відмінити</Button>
      </DialogActions>
    </Dialog>
  )

  return (
    <>
      {dialog}
      <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }} {...props} >
        <TextField
          margin="normal"
          required
          fullWidth
          id="entreprise"
          label="Код"
          name="entreprise"
          autoFocus
          value={enterprise}
          onInput={e => setEnterprise(e.target.value)}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          id="phone"
          label="Телефон"
          name="phone"
          value={phone}
          onInput={e => setPhone(e.target.value)}
        />
        <Button 
            type="submit" 
            disabled={enterprise.trim() === '' || phone.trim() === ''} 
            fullWidth 
            variant="contained" 
            sx={{ mt: 3, mb: 2 }}>
          Вхід
        </Button>
      </Box>
    </>
  )
}


export default function SignIn() {
  document.title = `EXMOTO: авторизація`
  const [typeLogin, setTypeLogin] = useState(LOGIN_INDIVIDUAL)
  localStorage.clear()

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            width: 1,
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}>
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">Авторизація</Typography>

          <FormControl>
            <RadioGroup row value={typeLogin} onChange={e => setTypeLogin(() => e.target.value)}>
              <FormControlLabel value={LOGIN_INDIVIDUAL} control={<Radio />} label="Фізична особа" />
              <FormControlLabel value={LOGIN_ENTERPRISE} control={<Radio />} label="Юридична особа" />
            </RadioGroup>
          </FormControl>

          {typeLogin == LOGIN_INDIVIDUAL ? <LoginIndividual /> : <LoginEnterprise />}

        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  )

}
