import React, { useContext } from 'react'
import Country from '../country'
import City from '../Сity'
import { InputText } from 'primereact/inputtext'
import '../../scss/order.scss'


export default function Address(props) {
  const [context, setContext] = useContext(props.context)


  return (
    <>
      <Country context={props.context} isReadOnly={true} />
      <div style={{ height: "1.5em" }} />
      <City context={props.context} />

      <div style={{ height: "1.5em" }} />
      <div className="p-fluid p-grid" style={{ marginLeft: "11px" }}>

        <div className="p-field p-col-12 p-md-8">
          <span className="p-float-label">
            <InputText id="Вулиця"
              value={context.street}
              disabled={props.isReadOnly}
              onChange={e => {
                let data = Object.assign({}, context)
                data.street = e.target.value
                setContext(data)
              }}
            />
            <label htmlFor="Вулиця">Вулиця</label>
          </span>
        </div>

        <div className="p-field p-col-6 p-md-2">
          <span className="p-float-label">
            <InputText id="<Будинок>"
              value={context.bldg}
              disabled={props.isReadOnly}
              onChange={(e) => {
                let data = Object.assign({}, context)
                data.bldg = e.target.value
                setContext(data)
              }}
            />
            <label htmlFor="Будинок">Будинок</label>
          </span>
        </div>

        <div className="p-field p-col-6 p-md-2">
          <span className="p-float-label">
            <InputText id="Кв-ра"
              value={context.flat}
              disabled={props.isReadOnly}
              onChange={(e) => {
                let data = Object.assign({}, context)
                data.flat = e.target.value
                setContext(data)
              }}
            />
            <label htmlFor="Кв-ра">Кв-ра</label>
          </span>
        </div>

      </div>

      <div className="p-fluid p-grid" style={{ marginLeft: "11px" }}>
        <div className="p-field p-col-12 p-md-12">
          <span className="p-float-label">
            <InputText id="Отримувач"
              value={context.client}
              disabled={props.isReadOnly}
              onChange={(e) => {
                let data = Object.assign({}, context)
                data.client = e.target.value
                setContext(data)
              }}
            />
            <label htmlFor="Отримувач">Компанія</label>
          </span>
        </div>

        <div className="p-field p-col-12 p-md-8">
          <span className="p-float-label">
            <InputText id="Контактна_особа"
              value={context.contact}
              disabled={props.isReadOnly}
              onChange={(e) => {
                let data = Object.assign({}, context)
                data.contact = e.target.value
                setContext(data)
              }}
            />
            <label htmlFor="Контактна особа">Контактна особа</label>
          </span>
        </div>

        <div className="p-field p-col-12 p-md-4">
          <span className="p-float-label">
            <InputText id="Телефон" className="raz"
              value={context.phone}
              disabled={props.isReadOnly}
              type="number"
              onChange={(e) => {
                let data = Object.assign({}, context)
                data.phone = e.target.value
                setContext(data)
              }}
            />
            <label htmlFor="Телефон">Телефон</label>
          </span>
        </div>
      </div>

    </>
  )

}
