import React from 'react'
import Menu from './Menu.js'

export default function MainExt() {

  return (
    <>
      <Menu />
    </>
  )
}