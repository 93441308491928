import React from 'react'
import { useState, useEffect, useRef } from 'react'
import { Calendar } from 'primereact/calendar'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrint, faCheckCircle, faSpinner, faMoneyBill, faEdit, faTrash, faCopy } from '@fortawesome/free-solid-svg-icons'
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Link } from 'react-router-dom'
import { Toast } from 'primereact/toast'
import '../../scss/viewttn.scss'
import { SERVER_API } from '../../common/env'
import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'
import ipay_logo from '../../img/ipay_logo.png'
import '../../scss/order.scss'
// import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog'


const PayDialog = props => {
    const { onHide, orderno, visible } = props
    let growl = useRef()

    const onPay = e => {
        document.getElementById('shadow').style.visibility = "visible"

        fetch(`${SERVER_API}ipay/checkout`, {
            method: 'POST',
            mode: 'cors',
            headers: new Headers({
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.getItem('jwt')
            }),
            body: JSON.stringify({ orderno: orderno }),
        })
            .then(response => {
                try {
                    if (!response.ok) {
                        throw new Error(`HTTP error! status: ${response.status}`);
                    }
                    return response.json()
                } finally {
                    document.getElementById('shadow').style.visibility = "hidden"
                }
            })
            .then(json => {
                const url = json.data.url
                console.log(url)
                window.location.href = url
            })
            .catch(error => {
                alert("Ошибка!")
                // growl.current.show({ severity: 'error', summary: 'Помилка', detail: "Помилка виконання!" })
                console.log(error.response)
                document.getElementById('shadow').style.visibility = "hidden"
            })
    }

    return (
        <Dialog header={`Оплата заказа № ${orderno}`} modal
            footer={<div><Button label="Отмена" onClick={() => onHide(false)} /></div>}
            visible={visible}
            style={{ width: 'auto' }}
            closable={false}
            onHide={e => onHide(false)} >
            <div className="wrapper-dialog-pay">
                <Link className="link_pay" to='#' onClick={onPay}>
                    <img src={ipay_logo} alt="" />
                </Link>
            </div>
        </Dialog>
    )
}

export { PayDialog }
