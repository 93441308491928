import { React, useState, useEffect, useRef, } from 'react'
import { Box, Button } from '@mui/material'
// import { Accordion, AccordionTab } from 'primereact/accordion'
import { Link } from 'react-router-dom'
import PrintIcon from '@mui/icons-material/Print'
import { SERVER_API, API_URL } from '../../../common/env'
import { Calendar } from 'primereact/calendar'
import VisibilityIcon from '@mui/icons-material/Visibility'
import CircularProgress from '@mui/material/CircularProgress'
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp'
import MuiAccordion from '@mui/material/Accordion'
import MuiAccordionSummary from '@mui/material/AccordionSummary'
import MuiAccordionDetails from '@mui/material/AccordionDetails'
import Typography from '@mui/material/Typography'


const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}))

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem', fontWeight: 'bold' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, .05)' : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}))

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}))


export default function ViewTtn(props) {

  const toast = useRef()

  const [date, setDate] = useState((() => {
    const d = new Date(), y = d.getFullYear(), m = d.getMonth()
    return [new Date(y, m, 1), new Date(y, m + 1, 0)]
  })())
  const [item, setItem] = useState(null)
  const [items, setItems] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const load = () => {
    setIsLoading(true)
    fetch(`${SERVER_API}/ttn/ent/?date1=${date[0]}&date2=${date[1]}`, {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('jwt')
      }
    })
      .then(data => { return data.json() })
      .then(json => {
        const data = json.data
        console.log(data)
        setItems(data)
      })
      .catch(err => {
        console.log(err)
        toast.current.show({ severity: 'error', summary: 'Помилка!', detail: `Помилка виконання` })
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const handleLoadClick = e => {
    if (!date) {
      toast.show({ severity: 'error', summary: 'Помилка!', detail: "Не вказано період!" })
      return
    }
    load()
  }

  useEffect(() => {
    document.title = `EXMOTO: Просмотр накладных [${localStorage.getItem('login')}]`
    load()
  }, [])


  const accordion = () => {
    // if (isLoading) return
    return items.map(item => {
      let d = item.dt_created
      d = d.substr(0, 10) + ' ' + d.substr(11, 5)
      return (
        <Accordion key={item.id}>
          <AccordionSummary
            sx={{
              '&:hover': {
                backgroundColor: 'lightgray'
              }
            }}
            // expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Box>
              <Box sx={{ display: 'flex', width: '100%' }}>
                <Typography sx={{ fontWeight: 'bold' }}>№: {item.wb_no}</Typography>
                <Typography sx={{}}><span style={{ marginLeft: '5px', color: 'gray' }}>Дата:</span> {item.dt_created.substr(0, 10)/* + ' ' + item.dt_created.substr(11, 5)*/}</Typography>
                <Typography sx={{}}><span style={{ marginLeft: '5px', color: 'gray' }}>Місто Отримувача:</span> {item.receiver_city_type_name}.{item.receiver_city_name}</Typography>
              </Box>
              <Box sx={{ display: 'flex', width: '100%' }}>
                <Typography sx={{ flexGrow: 1 }}><span style={{ marginLeft: '5px', color: 'gray' }}>Отримувач:</span> {item.receiver_client_name}</Typography>
                <Typography sx={{ flexGrow: 1 }}><span style={{ marginLeft: '5px', color: 'gray' }}>Адреса:</span> {item.sender_street_name}</Typography>
              </Box>
              <Box sx={{ display: 'flex', width: '100%' }}>
                <Typography sx={{ flexGrow: 1 }}><span style={{ color: 'gray' }}>Посл:</span> {item.service_id}</Typography>
                <Typography sx={{ flexGrow: 1 }}><span style={{ color: 'gray' }}>Місць:</span> {item.place_count}</Typography>
                <Typography sx={{ flexGrow: 1 }}><span style={{ color: 'gray' }}>Вага:</span> {item.weight}</Typography>
                <Typography sx={{ flexGrow: 1 }}><span style={{ color: 'gray' }}>Доставл:</span> {item.pod ? item.pod.substr(0, 10) : ''} {item.pod ? item.pod.substr(11, 5) : ''}</Typography>
                <Typography sx={{ flexGrow: 2 }}><span style={{ color: 'gray' }}>Примітка:</span> {item.note}</Typography>
              </Box>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <a href={`${API_URL}/ttn/print/ent?id=${item.id}&jwt=${localStorage.getItem('jwt')}`} target="_blank">
              <PrintIcon />&nbsp;Друк
            </a>
            <Box>
              <div className="box-table">
                <div>
                  <table>
                    <thead>
                      <tr><th colSpan={2}>Відправник</th></tr>
                    </thead>
                    <tbody>
                      <tr><td>Країна</td><td>{item.sender_country_name}</td></tr>
                      <tr><td>Нас.пункт</td><td>{item.org}&nbsp;&nbsp;{item.sender_city_type_name}.{item.sender_city_name}</td></tr>
                      <tr><td>Область</td><td>{item.sender_state_name} {item.sender_state_type_name}</td></tr>
                      <tr><td>Район</td><td>{item.sender_distr_name || ''}</td></tr>
                      <tr><td>Вулиця</td><td>{item.sender_street_name || '' + item.sender_street_type || ''}</td></tr>
                      <tr><td>Будинок</td><td>{item.sender_bldg || ''}</td></tr>
                      <tr><td>Квартира</td><td>{item.sender_flat || ''}</td></tr>
                      <tr><td>Компанiя</td><td>{item.sender_client_name}</td></tr>
                      <tr><td>Контакта особа</td><td>{item.sender_contact_name}</td></tr>
                      <tr><td>Телефон</td><td>{item.sender_phone}</td></tr>
                    </tbody>
                  </table>
                </div>
                <div>
                  <table>
                    <thead>
                      <tr><th colSpan={2}>Отримувач</th></tr>
                    </thead>
                    <tbody>
                      <tr><td>Країна</td><td>{item.receiver_country_name}</td></tr>
                      <tr><td>Нас.пункт</td><td>{item.org}&nbsp;&nbsp;{item.receiver_city_type_name}.{item.receiver_city_name}</td></tr>
                      <tr><td>Область</td><td>{item.receiver_state_name} {item.receiver_state_type_name}</td></tr>
                      <tr><td>Район</td><td>{item.receiver_distr_name ? item.receiver_distr_name : ''}</td></tr>
                      <tr><td>Вулиця</td><td>{item.receiver_street_name || '' + item.receiver_street_type || ''}</td></tr>
                      <tr><td>Будинок</td><td>{item.receiver_bldg || ''}</td></tr>
                      <tr><td>Квартира</td><td>{item.receiver_flat || ''}</td></tr>
                      <tr><td>Компанiя</td><td>{item.receiver_client_name}</td></tr>
                      <tr><td>Контакта особа</td><td>{item.receiver_contact_name}</td></tr>
                      <tr><td>Телефон</td><td>{item.receiver_phone}</td></tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <hr />

              <div className="box-table">
                <div>
                  <table>
                    <thead>
                      <tr><th colSpan={2}>Параметри</th></tr>
                    </thead>
                    <tbody>
                      <tr><td>Платник</td><td>{item.payer_name}</td></tr>
                      <tr><td>Кількість місць</td><td>{item.place_count}</td></tr>
                      <tr><td>Сплата</td><td>{item.payment_name}</td></tr>
                    </tbody>
                  </table>
                </div>
                <div>
                  <table>
                    <thead>
                      <tr><th colSpan={2} style={{ color: "white" }}>Параметри</th></tr>
                    </thead>
                    <tbody>
                      <tr><td>Формат</td><td>{item.cargo_name}</td></tr>
                      <tr><td>Доставка</td><td>{item.delivery_name}</td></tr>
                      <tr><td>Оціночна вартість, грн.</td><td>{item.cost.toFixed(2)}</td></tr>
                    </tbody>
                  </table>
                </div>
                <div>
                  <table>
                    <thead>
                      <tr><th colSpan={2} style={{ color: "white" }}>Параметри</th></tr>
                    </thead>
                    <tbody>
                      <tr><td>Сервіс</td><td>{item.service_name}</td></tr>
                      <tr><td>Тип вкладення</td><td>{item.attachment_name}</td></tr>
                      <tr><td>Вага, кг</td><td>{item.weight}</td></tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <hr />

              {/* <div className="box-table">
                <div className="chg">
                  <table>
                    <thead>
                      <tr><th colSpan={2}>Розрахункова вартість доставки</th></tr>
                    </thead>
                    <tbody>
                      <tr><td>Основной (базовый) тариф без НДС, грн.</td><td>{item.b_chg.toFixed(2)}</td></tr>
                      <tr><td>Оценочный тариф (1% от заявленной стоимости), грн.</td><td>{item.v_chg.toFixed(2)}</td></tr>
                      <tr><td>Дополнительный тариф за дооформление, грн.</td><td>{item.a_chg.toFixed(2)}</td></tr>
                      <tr><td>Сумма тарифов без НДС, грн.</td><td>{(item.b_chg + item.v_chg + item.a_chg).toFixed(2)}</td></tr>
                      <tr><td>НДС, грн.</td><td>{((item.b_chg + item.v_chg + item.a_chg) * 0.2).toFixed(2)}</td></tr>
                      <tr><td>Итого с НДС, грн.</td><td>{item.t_chg.toFixed(2)}</td></tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <hr /> */}

              <div className="box-textarea" data-note={'Oпис'}>
                <p>{item.note}</p>
              </div>

              <hr />
            </Box>
          </AccordionDetails>
        </Accordion>
      )
    })
  }



  return (
    <>
      <Box>
        <Box sx={{ display: 'flex' }}>
          <Link to="/ent">Головне меню</Link>
          &nbsp;&nbsp;<span> - Перегляд ТТН</span>
        </Box>
        <Link to="/ent/ttn">Створити ТТН</Link>
      </Box>

      <Box sx={{ padding: '10px 0' }}>
        <span>Період</span>
        <Calendar
          selectionMode="range"
          dateFormat="dd.mm.yy"
          value={date}
          onChange={e => setDate(e.value)}
          disabled={isLoading} />
        {isLoading ?
          <CircularProgress size="20px" /> :
          // <Link onClick={handleLoadClick}><VisibilityIcon /></Link>
          <Button onClick={handleLoadClick}><VisibilityIcon /></Button>
        }

      </Box>


      {items.length ? accordion() : <span style={{ color: "gray", fontSize: "2em" }}>Відсутні дані для перегляду</span>}
    </>
  )

}
